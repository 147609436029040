import React, { useEffect, useState } from 'react';
import { useSearchParams, Link } from 'react-router-dom';
import { useLanguage } from '../context/LanguageContext';
import { useTranslation } from 'react-i18next';
import { Card } from '../components/card';

interface SearchResult {
  id: number;
  type: 'section' | 'section-page' | 'press-releases-article' | 'news' | 'offers-calls';
  attributes: {
    title_fr: string;
    title_ar: string;
  };
}

const SearchResults: React.FC = () => {
  const [searchParams] = useSearchParams();
  const { language } = useLanguage();
  const { t } = useTranslation();
  const [results, setResults] = useState<SearchResult[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const searchTerm = searchParams.get('q');
    if (searchTerm) {
      fetchSearchResults(searchTerm);
    }
  }, [searchParams]);

  const fetchSearchResults = async (searchTerm: string) => {
    setLoading(true);
    try {
      const [
        sectionsResponse,
        sectionPagesResponse,
        pressReleasesArticlesResponse,
        newsResponse,
        offersCallsResponse
      ] = await Promise.all([
        fetch(`https://api.sitewebbcm.com/api/Sections?filters[$or][0][title_${language}][$containsi]=${searchTerm}&populate=*`),
        fetch(`https://api.sitewebbcm.com/api/Section-Pages?filters[$or][0][title_${language}][$containsi]=${searchTerm}&populate=*`),
        fetch(`https://api.sitewebbcm.com/api/Press-relases-articles?filters[$or][0][title_${language}][$containsi]=${searchTerm}&populate=*`),
        fetch(`https://api.sitewebbcm.com/api/News?filters[$or][0][title_${language}][$containsi]=${searchTerm}&populate=*`),
        fetch(`https://api.sitewebbcm.com/api/offers-calls?filters[$or][0][title_${language}][$containsi]=${searchTerm}&populate=*`)
      ]);

      const [
        sectionsData,
        sectionPagesData,
        pressReleasesArticlesData,
        newsData,
        offersCallsData
      ] = await Promise.all([
        sectionsResponse.json(),
        sectionPagesResponse.json(),
        pressReleasesArticlesResponse.json(),
        newsResponse.json(),
        offersCallsResponse.json()
      ]);

      const combinedResults: SearchResult[] = [
        ...sectionsData.data.map((item: any) => ({ ...item, type: 'section' })),
        ...sectionPagesData.data.map((item: any) => ({ ...item, type: 'section-page' })),
        ...pressReleasesArticlesData.data.map((item: any) => ({ ...item, type: 'press-releases-article' })),
        ...newsData.data.map((item: any) => ({ ...item, type: 'news' })),
        ...offersCallsData.data.map((item: any) => ({ ...item, type: 'offers-calls' }))
      ];

      setResults(combinedResults);
    } catch (error) {
      console.error('Error fetching search results:', error);
    } finally {
      setLoading(false);
    }
  };

  const getItemLink = (item: SearchResult) => {
    switch (item.type) {
      case 'section':
        return `/pages/${item.id}`;
      case 'section-page':
        return `/section/${item.id}`;
      case 'press-releases-article':
        return `/press-release/${item.id}`;
      case 'news':
        return `/news/${item.id}`;
      case 'offers-calls':
        return `/offers-calls/${item.id}`;
      default:
        return '#';
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center mt-28">
        <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full border-primary border-t-transparent" role="status" />
      </div>
    );
  }

  return (
    <div className="mx-auto sm:p-6 lg:p-8 bg-white border border-gray-300 p-3 rounded-lg shadow-md">
      <h1 className="text-2xl sm:text-3xl lg:text-4xl font-bold mb-4 text-primary">
        {t('searchResults', 'Search Results')}
      </h1>
      {results.length === 0 ? (
        <p>{t('noResultsFound', 'No results found.')}</p>
      ) : (
        <ul className="flex flex-col gap-2 mt-4">
          {results.map((item) => (
            <Card
              href={getItemLink(item)}
              key={`${item.type}-${item.id}`}
            >
              <div className="flex gap-x-4 items-center">
                <p className="text-darkGray font-Montserrat font-semibold text-sm">
                  {language === 'fr' ? item.attributes.title_fr : item.attributes.title_ar}
                </p>
                {/* <span className="text-xs text-gray-500">
                  {t(item.type, item.type)}
                </span> */}
              </div>
            </Card>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SearchResults;