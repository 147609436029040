import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../context/LanguageContext';
import { Title } from '../components/components';
import logo from "../assets/images/logo.png";

interface GenericArticleData {
  id: number;
  attributes: {
    title_fr: string;
    title_ar: string;
    limit_date : string;
    date: string;
    category: string;
    author: string;
    createdAt: string;
    [key: string]: any;
  };
}

interface ArticleListingProps {
  type: string;
}

interface PaginationData {
  page: number;
  pageSize: number;
  pageCount: number;
  total: number;
}

const fetchArticlesData = async (type: string, page: number, pageSize: number) => {
  const response = await fetch(`https://api.sitewebbcm.com/api/${type}?populate=*&pagination[page]=${page}&pagination[pageSize]=${pageSize}`);
  return response.json();
};

const GenericArticleListing: React.FC<ArticleListingProps> = ({ type }) => {
  const [articles, setArticles] = useState<GenericArticleData[] | null>(null);
  const [pagination, setPagination] = useState<PaginationData | null>(null);
  const { t } = useTranslation();
  const { language } = useLanguage();
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10; 

  useEffect(() => {
    if (!type) return;
    fetchArticlesData(type, currentPage, pageSize)
      .then((data) => {
        setArticles(data.data);
        setPagination(data.meta.pagination);
      })
      .catch((error) => console.error('Error fetching articles:', error));
  }, [type, currentPage]);

  if (!articles || !pagination) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-green-500"></div>
      </div>
    );
  }

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  return (
    <div className="flex flex-col mx-auto sm:p-6 lg:p-8 bg-white border border-gray-300 p-3 rounded-lg shadow-md">
      {articles.map((article) => {
        const title = language === 'fr' ? article.attributes.title_fr : article.attributes.title_ar;
        return (
          <div key={article.id} className="mb-8 border-b pb-4">
            <h2 className="text-2xl font-bold mb-2">{title}</h2>
            <div className="flex items-center text-sm text-gray-600 mb-6 gap-4">
              
              <span>{t('Date de publication')} :</span>
              <span>{new Date(article.attributes.createdAt).toLocaleDateString()}</span>
            </div>

            <div className="flex items-center text-sm text-gray-600 mb-6 gap-4">
              
              <span>{t('Date limite de réception des offres')} :</span>
              <span>{new Date(article.attributes.limit_date).toLocaleDateString()}</span>
            </div>
            <img src={logo} alt="Logo" className="mb-6" />
            <Link
              to={`/press-release/${article.id}`}
              className="bg-primary text-white py-2 px-4 rounded inline-block"
            >
              Lire plus
            </Link>
          </div>
        );
      })}

      {/* Pagination */}
      <div className="flex justify-center items-center mt-8">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="px-4 py-2 mr-2 bg-gray-200 rounded disabled:opacity-50"
        >
          Précédent
        </button>
        <span className="mx-4">
          Page {currentPage} de {pagination.pageCount}
        </span>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === pagination.pageCount}
          className="px-4 py-2 ml-2 bg-gray-200 rounded disabled:opacity-50"
        >
          Suivant
        </button>
      </div>
    </div>
  );
};

export default GenericArticleListing;