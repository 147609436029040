import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useLanguage } from "../context/LanguageContext";
import { ArrowUpCircle, ArrowDownCircle, MinusCircle } from 'lucide-react';

interface Money {
  id: number;
  attributes: {
    name_fr: string;
    name_ar: string;
    unity: number;
    code: string;
    money_today_changes: {
      data: Array<{
        attributes: {
          day: string;
          value: string;
          type: string;
        }
      }>
    }
  }
}

const MoneyRateTable: React.FC = () => {
  const { language } = useLanguage();
  const isRTL = language === 'ar';
  
  const { t } = useTranslation();
  
  const [currencies, setCurrencies] = useState<Money[]>([]);
  
  useEffect(() => {
    fetch('https://api.sitewebbcm.com/api/Monies?populate=*&pagination[limit]=-1', {
      headers: {
      }
    })
      .then(response => response.json())
      .then((data: { data: Money[] }) => setCurrencies(data.data))
      .catch(error => console.error('Error fetching currencies:', error));
  }, []);

  const getTodayValue = (money: Money): string => {
    const today = new Date().toISOString().split('T')[0];
    const todayChange = money.attributes.money_today_changes.data.find(change => change.attributes.day === today && change.attributes.type === 'REFERENCE');
    return todayChange ? todayChange.attributes.value : 'N/A';
  };

  const getYesterdayValue = (money: Money): string => {
    const yesterday = new Date(Date.now() - 86400000).toISOString().split('T')[0];
    const yesterdayChange = money.attributes.money_today_changes.data.find(change => change.attributes.day === yesterday && change.attributes.type === 'REFERENCE');
    return yesterdayChange ? yesterdayChange.attributes.value : 'N/A';
  };

  const calculateTrend = (todayValue: string, yesterdayValue: string): { icon: JSX.Element, percentage: string } => {
    if (todayValue === 'N/A' || yesterdayValue === 'N/A') {
      return { icon: <MinusCircle className="inline-block ml-2" size={16} />, percentage: '0%' };
    }

    const today = parseFloat(todayValue);
    const yesterday = parseFloat(yesterdayValue);
    const difference = today - yesterday;
    const percentageChange = (difference / yesterday) * 100;

    if (difference > 0) {
      return { 
        icon: <ArrowUpCircle className="inline-block ml-2 text-green-500" size={16} />, 
        percentage: `+${percentageChange.toFixed(2)}%` 
      };
    } else if (difference < 0) {
      return { 
        icon: <ArrowDownCircle className="inline-block ml-2 text-red-500" size={16} />, 
        percentage: `${percentageChange.toFixed(2)}%` 
      };
    } else {
      return { icon: <MinusCircle className="inline-block ml-2" size={16} />, percentage: '0%' };
    }
  };

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8">
      <table className="min-w-full divide-y divide-black mt-6">
        <thead className="bg-primary">
          <tr>
            <th className="p-2 text-center text-sm font-medium text-white">{t('QUANTITE')}</th>
            <th className="p-2 text-center text-sm font-medium text-white">{t('DEVISE')}</th>
            <th className="p-2 text-center text-sm font-medium text-white">{t('CODE')}</th>
            <th className="p-2 text-center text-sm font-medium text-white">{t('COURS DE REFERENCE R')}</th>
            <th className="p-2 text-center text-sm font-medium text-white">{t('TENDANCE')}</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-x divide-gray-200">
          {currencies.map(currency => {
            const todayValue = getTodayValue(currency);
            const yesterdayValue = getYesterdayValue(currency);
            const { icon, percentage } = calculateTrend(todayValue, yesterdayValue);
            return (
              <tr key={currency.id}>
                <td className="p-2 text-center text-sm text-gray-900">{currency.attributes.unity}</td>
                <td className="p-2 text-center text-sm text-gray-900">{currency.attributes.name_fr}</td>
                <td className="p-2 text-center text-sm text-gray-900">{currency.attributes.code}</td>
                <td className="p-2 text-center text-sm text-gray-900">{todayValue}</td>
                <td className="p-2 text-center text-sm text-gray-900">
                  {icon}
                  <span className="ml-1">{percentage}</span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default MoneyRateTable;