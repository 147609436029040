import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ArrowIcon } from '../ROUTES/home_page';

interface Image {
  id: number;
  attributes: {
    url: string;
    name: string;
    type: string;
  };
}

interface ImageCarouselProps {
  images: Image[];
}

const ImageCarousel: React.FC<ImageCarouselProps> = ({ images }) => {
  const [selectedImage, setSelectedImage] = useState<Image | null>(null);

  const CustomPrevArrow: React.FC<any> = ({ onClick }) => (
    <button
      className="absolute left-0 top-1/2 transform -translate-y-1/2 z-10 bg-primary bg-opacity-80 text-white p-2 rounded-r"
      onClick={onClick}
    >
      <ArrowIcon className="stroke-white" />
    </button>
  );

  const CustomNextArrow: React.FC<any> = ({ onClick }) => (
    <button
      className="absolute right-0 top-1/2 transform -translate-y-1/2 z-10 bg-primary bg-opacity-80 text-white p-2 rounded-l"
      onClick={onClick}
    >
      <ArrowIcon direction="right" className="stroke-white" />
    </button>
  );

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 5000,
    swipe: false,
    draggable: false,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  const handleImageClick = (image: Image) => {
    setSelectedImage(image);
  };

  const closePopup = () => {
    setSelectedImage(null);
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="relative">
        <Slider {...settings}>
          {images?.map((image, index) => (
            <div key={index} className="px-2">
              <img
                src={image.attributes.url}
                alt={image.attributes.name}
                className="w-full h-80 object-cover rounded-lg cursor-pointer"
                onClick={() => handleImageClick(image)}
              />
            </div>
          ))}
        </Slider>
      </div>

      {selectedImage && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50" 
          onClick={closePopup}
        >
          <div className="max-w-4xl max-h-[90vh] overflow-auto">
            <img
              src={selectedImage.attributes.url}
              alt={selectedImage.attributes.name}
              className="max-w-full max-h-full object-contain"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageCarousel;