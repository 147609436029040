import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../context/LanguageContext';
import { Title } from '../components/components';
import { Card } from '../components/card';


interface PressReleaseArticle {
  id: number;
  attributes: {
    title_fr: string;
    title_ar: string;
    content_fr: string;
    content_ar: string;
    createdAt: string;
  };
}

interface PressRelease {
  id: number;
  attributes: {
    title_fr: string;
    title_ar: string;
    content_fr: string;
    content_ar: string;
    press_relases_articles: {
      data: PressReleaseArticle[];
    };
  };
}

const PresseRelaseListing: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [pressRelease, setPressRelease] = useState<PressRelease | null>(null);
  const { t } = useTranslation();
  const { language } = useLanguage();

  useEffect(() => {
    const fetchPressRelease = async () => {
      try {
        const response = await fetch(`https://api.sitewebbcm.com/api/press-releases/${id}?populate=*`);
        const data = await response.json();
        setPressRelease(data.data);
      } catch (error) {
        console.error('Error fetching press release:', error);
      }
    };

    if (id) {
      fetchPressRelease();
    }
  }, [id]);

  if (!pressRelease) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-green-500"></div>
      </div>
    );
  }

  const { title_fr, title_ar, content_fr, content_ar, press_relases_articles } = pressRelease.attributes;

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <Title>{language === 'fr' ? title_fr : title_ar}</Title>
      <div className="mt-4 bg-white border border-gray-300 p-6 rounded-lg shadow-md">
        <ul className="flex flex-col gap-4">
          {press_relases_articles.data.map((article) => (
            <Card key={article.id} href={`/press-release/${article.id}`}>
              <div className="flex flex-col gap-2">
                <p className="text-darkGray font-Montserrat font-semibold text-sm">
                  {language === 'fr' ? article.attributes.title_fr : article.attributes.title_ar}
                </p>
                <p className="text-sm text-white">
                  {new Date(article.attributes.createdAt).toLocaleDateString()}
                </p>
              </div>
            </Card>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default PresseRelaseListing;