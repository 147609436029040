import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png';
import { Facebook, Linkedin, Youtube } from 'lucide-react';
import { useLanguage } from '../context/LanguageContext';

const Footer = ({ headerItems, menuItems }) => {
  const { t } = useTranslation();
  const { language } = useLanguage();
  const isRTL = language === 'ar';

  const renderLinks = (items) => {
    return items.map((item) => (
      <li key={item.id}>
        <Link 
          to={item.link} 
          className="font-bold hover:text-green-300 transition duration-300"
        >
          {language === 'fr' ? item.title_fr : item.title_ar}
        </Link>
      </li>
    ));
  };

  return (
    <footer className="text-white pt-8 relative mt-10">
      <div className="container mx-auto px-4 relative z-10">
        <div className="flex flex-col gap-8 justify-between items-center mb-8">
          <img src={logo} alt="Banque Centrale de Mauritanie Logo" className="h-24 mb-4 md:mb-0" />
          <nav>
            <ul className="flex flex-wrap justify-center  gap-4">


            {renderLinks(menuItems)}
              {renderLinks(Object.entries(headerItems).map(([name, link], index) => ({
                id: `header-${index}`,
                title_fr: name,
                title_ar: t(name),
                link
              })))}
            </ul>
          </nav>
        </div>
        <div className="flex justify-center space-x-4 mb-8">
          <a href="https://twitter.com/bcmauritanie" aria-label="Twitter" className="hover:text-green-300 transition duration-300">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
              <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
            </svg>
          </a>
          <a href="https://facebook.com/BanqueCentraledeMauritanie" aria-label="Facebook" className="hover:text-green-300 transition duration-300">
            <Facebook />
          </a>
          <a href="https://www.linkedin.com/company/banque-centrale-de-mauritanie" aria-label="LinkedIn" className="hover:text-green-300 transition duration-300">
            <Linkedin />
          </a>

          <a href="https://www.youtube.com/@BanqueCentraledeMauritanie" aria-label="LinkedIn" className="hover:text-green-300 transition duration-300">
            <Youtube />
          </a>
        </div>
        <div className="text-center pb-6">
          <p>{t('Copyright BCM - 2024 Avenue de l\'indépendance BP 623 Nouakchott Mauritanie')}</p>
        </div>
      </div>

      <div className="absolute inset-0 w-full h-full overflow-hidden" >
        <svg
          viewBox="0 0 900 200"
          xmlns="http://www.w3.org/2000/svg"
          className="w-full h-full"
          preserveAspectRatio="none"
        >
          <path d="M0 1L180 -2L360 36L540 -3L720 -16L900 24L900 201L720 201L540 201L360 201L180 201L0 201Z" fill="#fff500" />
          <path d="M0 29L180 44L360 3L540 7L720 20L900 46L900 201L720 201L540 201L360 201L180 201L0 201Z" fill="#cde415" />
          <path d="M0 34L180 87L360 24L540 74L720 30L900 84L900 201L720 201L540 201L360 201L180 201L0 201Z" fill="#9dd126" />
          <path d="M0 88L180 89L360 115L540 69L720 119L900 86L900 201L720 201L540 201L360 201L180 201L0 201Z" fill="#71bd32" />
          <path d="M0 107L180 118L360 128L540 126L720 101L900 135L900 201L720 201L540 201L360 201L180 201L0 201Z" fill="#44a83a" />
          <path d="M0 169L180 136L360 166L540 176L720 176L900 177L900 201L720 201L540 201L360 201L180 201L0 201Z" fill="#00923f" />
        </svg>
      </div>
    </footer>
  );
};

export default Footer;